
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import Button from '../../../atomic/atoms/Button/Button.vue';
import Link from '../../../atomic/atoms/Link/Link.vue';
import Input from '../../../atomic/atoms/Input/Input.vue';
import AuthTemplate from '../../../atomic/templates/AuthTemplate/AuthTemplate.vue';
import { FormBuilder, FormControl } from '../../../utilities/Forms';
import { Validators } from '../../../utilities/Validators/Validators';

export default defineComponent({
  components: {
    'auth-template': AuthTemplate,
    'arc-button': Button,
    'arc-link': Link,
    'arc-input': Input,
  },

  data(): {
    inputType: string;
    invalidForm: boolean;
    formData: FormBuilder;
    reroutePath: any;
    invalidErrorMessage: string;
  } {
    return {
      inputType: 'password',
      invalidForm: false,
      formData: new FormBuilder({
        email: new FormControl('', [Validators.email, Validators.required]),
        password: new FormControl('', [Validators.required]),
      }),
      reroutePath: '',
      invalidErrorMessage:
        'Something went wrong. Please try again. If you continue to experience an issue, please email team@engagementagents.com for assistance.',
    };
  },

  computed: {
    ...mapGetters(['isAuthenticated', 'user']),
  },

  methods: {
    ...mapActions([
      'authenticate',
      'unauthenticate',
      'updateCurrentUser',
      'closeSidebar',
      'fetchMarketingCampaigns',
      'fetchRecruitmentCampaigns',
      'fetchMallMemos',
      'fetchStoreMemos',
      'fetchRecruitmentCampaignsReport',
      'fetchMalls',
      'fetchStores',
      'fetchCompanies',
      'fetchBrands',
      'fetchUsers',
      'fetchEmailConnections',
      'sendResetCode',
    ]),

    async submitForm(): Promise<void> {
      const query: string = Object.keys(this.$route.query)
        .map((key: string) => `${key}=${this.$route.query[key]}`)
        .join('&')
        .replace('reroute=', '');

      this.reroutePath = query;

      this.formData.markAllAsTouched();

      if (this.formData.hasErrors) {
        return;
      }

      let email: string = this.formData.controls.email.value as string;

      try {
        const user = await this.authenticate({
          email: email.trim(),
          password: this.formData.controls.password.value,
        });

        await this.updateCurrentUser(user);
        window.localStorage.setItem('userId', user.userId);
      } catch (error) {
        let e: any = error;
        this.invalidForm = true;
        if (e?.info?.message === 'Attempt 2') {
          this.invalidErrorMessage =
            'Warning: You have one login attempt remaining before your account is locked. Please double-check your credentials carefully.';
        } else if (e?.info?.message === 'Locked Out') {
          this.$notify({
            title: 'LOCKED OUT',
            text:
              "Your account has been locked due to too many failed login attempts. We have sent an email with a code to reset your password and reactivate your account. Please enter the code you received. If you haven't received the email or are having trouble resetting your password, please email team@engagementagents.com for assistance.",
            type: 'error',
            duration: 30000,
          });
          const emailSent = await this.sendResetCode(this.formData.controls.email.value);
          if (emailSent) {
            this.$router.push('code');
          } else {
            this.$notify({
              title: 'SOMETHING LOOKS OFF',
              text:
                'Something went wrong. Please try again. If you continue to experience an issue, please either contact your Engagement Agents account manager, or call (416) 577-7326',
              type: 'error',
              duration: 5000,
            });

            setTimeout(() => {
              this.$router.push('login');
            }, 2000);
          }
        }
        return;
      }

      this.closeSidebar();
      if (this.reroutePath && !this.circleRoute(this.reroutePath)) {
        // check if reroutePath is a valid url, if so, only use the pathname
        try {
          const url = new URL(this.reroutePath);
          this.reroutePath = `${url.pathname}${url.search}`;
        } finally {
          this.$router.push(this.reroutePath);
        }
      } else {
        this.$router.push('/dashboard');
      }
    },
    circleRoute(route: string) {
      return (
        route === 'https://dev.engagementagents.com/' ||
        route === 'https://dev.engagementagents.com/login' ||
        route === 'https://app.engagementagents.com/' ||
        route === 'https://app.engagementagents.com/login' ||
        route === 'https://app2.engagementagents.com/' ||
        route === 'https://app2.engagementagents.com/login' ||
        route === 'http://localhost:8080/' ||
        route === 'http://localhost:8080/login'
      );
    },
    validateControl(controlName: string): void {
      this.formData.controls[controlName].validate();
    },
    toggleShow(): void {
      this.inputType = this.inputType === 'password' ? 'text' : 'password';
    },
  },
});
